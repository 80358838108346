import "./dashboard.scss";

import { useEffect, useRef, useState } from "react";

import {
  getAssessmentQuestionnaire,
  getCertificationProgress,
  getProductStaticPrice,
  onUpdateProgress,
  updateCertificationProgress,
} from "api";
import { getCertificationInfo } from "api/functions/certification/info";
import {
  Confetti,
  DashboardCertificateHistory,
  DashboardCertSummary,
  InfoBar,
  MetaDescription,
  NavBreadcrumbList,
  Progress,
  ProgressStatus,
  Spinner,
  UpgradeBlock,
  UpgradeNotificationBox,
  WelcomeText,
  WorkbookFullWidth,
} from "components";
import { useAppSessionState, useLoadingState } from "middleware";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { clearUrlParamsState, openZEWidget } from "services";
import { CERTIFICATION_INFO, ROUTES } from "variables";

export function Dashboard({ user, viewPassCertificate }) {
  const [progress, setProgress] = useState("NOT-SET");
  const [panelRef, setPanelRef] = useState();
  const { loading, setLoading } = useLoadingState();
  const [internalLoading, setInternalLoading] = useState();
  const [infoBar, setInfoBar] = useState("default");
  const [postPayment, setPostPayment] = useState(false);
  const [certificationInfo, setCertificationInfo] = useState();
  const [recommendation, setRecommendation] = useState();
  const { appSession } = useAppSessionState();
  const [prices, setPrices] = useState([]);
  const location = useLocation();
  const confettiRef = useRef();

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (!!location.search) {
      const params = queryString.parse(location.search);
      if (params["zewidget"] === "1") {
        setTimeout(() => {
          openZEWidget();
        }, 1000);
      }
      if (params["payment_intent"] === "success") {
        setInternalLoading(true);
        setPostPayment(true);
        setTimeout(() => {
          window.location.href = `${window.location.origin}?confetti=true&payment_success=true`;
        }, 7000);
      }
      if (params["confetti"] === "true") {
        clearUrlParamsState();
        confettiRef.current.startAnimation();
      }
      if (params["payment_success"] === "true") {
        setInfoBar("payment_success");
        setTimeout(() => setInfoBar("default"), 30000);
        clearUrlParamsState();
      }
      if (params["assessment_tool_panel"] === "true") {
        clearUrlParamsState();
        setTimeout(
          () =>
            document
              .getElementById("dashboard-assessment-tool-panel")
              ?.scrollIntoView({ block: "start", inline: "center" }),
          2000,
        );
      }
    }
  }, []);

  const setup = async () => {
    const certificationInfo = await getCertificationInfo();

    setProgress(certificationInfo?.customer?.certification?.progress);
    setCertificationInfo(certificationInfo);

    setRecommendation(await getAssessmentQuestionnaire());

    const staticPrices = (
      await Promise.all(
        // [1, 2, 3, 4, 5]
        [1, 2, 3].map((l) =>
          getProductStaticPrice({
            item: `LEVEL_${l}`,
          }),
        ),
      )
    ).map((p) => p.price);

    setPrices(staticPrices);
  };

  useEffect(() => {
    if (!!panelRef) {
      if (location.hash === "#panel") {
        setTimeout(() => scrollToPanel(), 1000);
      }
    }
  }, [panelRef]);

  useEffect(() => {
    setup();
  }, [user]);

  useEffect(() => {
    if (
      !!user &&
      !!certificationInfo?.organisation &&
      progress !== "NOT-SET" &&
      !!recommendation
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [progress, recommendation, certificationInfo]);

  useEffect(() => {
    if (progressSet()) {
      getCertificationProgress(progress.id).then((prog) => {
        if (prog?.status === "COMPLETED" && !prog?.confetti) {
          updateCertificationProgress({
            id: progress.id,
            confetti: true,
          }).then(() => {
            confettiRef.current.startAnimation();
            scrollToPanel();
          });
        }
      });
      return onUpdateProgress({
        id: progress.id,
        callbackFn: (result) => {
          setProgress(result);
        },
      });
    }
  }, [progress]);

  const scrollToPanel = () => {
    if (!!panelRef) {
      panelRef.scrollIntoView({ block: "center", inline: "nearest" });
    }
  };

  const progressSet = () => {
    return !!progress && progress !== "NOT-SET";
  };

  return (
    <>
      <MetaDescription
        pageTitle="Cyber security certification for your business"
        pageDescription="Be guided in your dashboard and complete your cyber security certification in CyberCert"
      />

      <div className="main-dashboard-container">
        {(loading.loading || internalLoading) && (
          <div className={`overlay white-bg`}>
            <Spinner svgTemplate={true} />
          </div>
        )}

        {
          {
            payment_success: (
              <InfoBar
                title="Congratulations!"
                content={`You have upgraded to Level ${progress?.level} - ${
                  CERTIFICATION_INFO[progress?.level]?.marketingName
                } successfully!`}
                level="success"
              />
            ),
            default: (
              // <InfoBar
              //   level="notice"
              //   title="Important Notice:"
              //   content="The CyberCert platform you are using is currently a"
              //   special="Release 20230601."
              // />
              <></>
            ),
          }[infoBar]
        }

        {progress !== "NOT-SET" && !!recommendation && (
          <>
            <div className="dashboard-header">
              <div className="container-md">
                <div className="df-row">
                  <div className="col-wl">
                    <div className="card-wl">
                      <div>
                        <NavBreadcrumbList
                          items={[{ to: ROUTES.ROOT, name: "Dashboard" }]}
                        />

                        <WelcomeText
                          progress={progress}
                          certificationInfo={certificationInfo}
                        />
                      </div>

                      <div className="progress-notif">
                        <ProgressStatus progress={progress} />

                        {!!progress &&
                          progress?.status === "COMPLETED" &&
                          progress?.level < 3 && <UpgradeNotificationBox />}

                        {/* {!!progress && progress?.status === "COMPLETED"
                          ? progress?.level < 3 && <UpgradeNotificationBox />
                          : !!assessment?.result &&
                            assessment?.result > 0 && (
                              <AssessmentNotification
                                assessment={assessment}
                                currentLevel={progress?.level}
                              />
                            )} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-pg">
                    <Progress viewDetails={scrollToPanel} />
                  </div>
                </div>
              </div>
            </div>

            {progressSet() && (
              <DashboardCertSummary progress={progress} setRef={setPanelRef} />
            )}

            {!progress ? (
              <UpgradeBlock prices={prices} assessment={recommendation} />
            ) : (
              <div className="assessment-result-block">
                {progressSet() && progress.status !== "COMPLETED" && (
                  <WorkbookFullWidth progress={progress} />
                )}
                <UpgradeBlock prices={prices} assessment={recommendation} />
              </div>
            )}

            {/* <Guide guide={guide} /> */}

            {/* {progressSet() && progress.status !== "COMPLETED" && (
          <Workbook progress={progress} />
        )}

        {progressSet() && <LevelDetails progress={progress} />} */}
            <DashboardCertificateHistory progress={progress} />
          </>
        )}
      </div>

      <Confetti ref={confettiRef} />
    </>
  );
}
