import {
  AttestationLink,
  CertificateLink,
  CredlyLink,
} from 'components';
import { useDownloadUrlState } from 'middleware';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { parseDate } from 'services';
import {
  CERT_LVL_ANIMS_CHROME,
  CERT_LVL_ANIMS_SAFARI,
  CERT_LVL_BADGES,
  CERT_LVLS,
} from 'variables';

import {
  StepIcon,
  StepIconArrow,
} from '../icons';

export function Step6({
  assessStep,
  setCurrentStep,
  done,
  progressData,
  stepNo = 6,
  level,
  getCurrentStep = () => {},
}) {
  const { downloadUrl } = useDownloadUrlState();

  return (
    <>
      <Card
        className={`cert-step-6 acc-item ${done ? "done" : getCurrentStep() === "6" ? "current" : ""}`}
      >
        <Card.Header>
          <div className="acc-header" onClick={() => setCurrentStep(6)}>
            {done ? <StepIcon /> : <StepIconArrow />}

            <h3>Step {stepNo}: Download your Certificate</h3>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="6">
          <Card.Body>
            <div className="acc-content">
              <div className="step-block">
                <div className="df-row">
                  <div className="col-content">
                    <div className="step-content">
                      <strong className="step-title">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C14.748 0 19 4.25202 19 9.5C19 14.748 14.748 19 9.5 19C4.25202 19 0 14.748 0 9.5C0 4.25202 4.25202 0 9.5 0ZM8.39294 5.50081L11.2851 8.27419H4.29032C3.78085 8.27419 3.37097 8.68407 3.37097 9.19355V9.80645C3.37097 10.3159 3.78085 10.7258 4.29032 10.7258H11.2851L8.39294 13.4992C8.02137 13.8554 8.01371 14.4492 8.37762 14.8131L8.79899 15.2306C9.15907 15.5907 9.74133 15.5907 10.0976 15.2306L15.1808 10.1512C15.5409 9.79113 15.5409 9.20887 15.1808 8.85262L10.0976 3.76552C9.7375 3.40544 9.15524 3.40544 8.79899 3.76552L8.37762 4.18306C8.01371 4.55081 8.02137 5.14456 8.39294 5.50081Z"
                            fill="black"
                          />
                        </svg>
                        Instructions
                      </strong>
                      <div className="step-text">
                        <p>
                          <strong>Congratulations!</strong> You have completed
                          your {["Bronze", "Silver", "Gold"][level - 1]} Cyber
                          Security Certification.
                        </p>
                        <p>
                          You can now download your certificate.{" "}
                          {!!progressData?.expiredDate && (
                            <>
                              Your {["Bronze", "Silver", "Gold"][level - 1]}{" "}
                              Certification is valid until{" "}
                              {parseDate(progressData?.expiredDate)}.
                            </>
                          )}
                        </p>
                        <p>
                          While your certification is valid, use your
                          certification badge on your website so your clients
                          can see you're cyber certified. Well done!
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-info">
                    <div
                      className="card-lvl card-lvl-animation"
                      id="certification-step-6-complete-panel"
                    >
                      <video
                        autoPlay
                        loop
                        playsInline
                        muted
                        width="100%"
                        height="100%"
                      >
                        <source
                          src={CERT_LVL_ANIMS_SAFARI[level]}
                          type='video/mp4; codecs="hvc1"'
                        />
                        <source
                          src={CERT_LVL_ANIMS_CHROME[level]}
                          type="video/webm"
                        />
                        <img
                          src={CERT_LVL_BADGES[level]}
                          className=""
                          alt={`${CERT_LVLS[level]} Badge`}
                        />
                      </video>

                      <h4 className="card-title">Download your Certificate</h4>
                      <ul className="download-list">
                        <li>
                          <CertificateLink
                            href={downloadUrl.certificateLink}
                            progress={progressData}
                          />
                        </li>

                        <li>
                          <AttestationLink
                            href={downloadUrl.signedDeedLink}
                            progress={progressData}
                          />
                        </li>

                        <li>
                          <CredlyLink
                            href={downloadUrl.badges}
                            progress={progressData}
                          />
                        </li>
                      </ul>

                      {/* <a
                        onClick={() => assessStep(6)}
                        className="btn btn-warning"
                      >
                        Complete
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
}
