/**
 * Smoothly scrolls the page to the element with the specified class name.
 *
 * @param {string} id - The class name of the target element to scroll to.
 * @param {number} [delay=0] - The delay in milliseconds before starting the scroll.
 */
export const scrollToElementSmoothly = (id, delay = 0) => {
  let elem = document.getElementsByClassName(id)[0];
  let startTime = 0;

  if (!elem) return;

  const easing = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

  // Calculate the distance from the top of the page
  const getTargetPos = () =>
    elem.getBoundingClientRect().top + window.scrollY - 160;

  const scrolling = (currentTime, duration) => {
    const distance = getTargetPos() - startPos;
    if (!startTime) startTime = currentTime;
    const progress = currentTime - startTime;
    const newPos = easing(progress / duration) * distance + startPos;
    window.scrollTo(0, newPos);
    if (progress < duration) {
      requestAnimationFrame((time) => scrolling(time, duration));
    }
  };

  const startPos = window.scrollY;
  const duration = Math.max(500, (getTargetPos() - startPos) / 10);

  setTimeout(
    () => requestAnimationFrame((time) => scrolling(time, duration)),
    delay,
  );
};
