import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import {
  getOrganisation,
  getUser,
  postSignupWithSSO as postSignupWithSSO_API,
  pushLoginEvent,
  verifyPartnerAccount,
} from "api";
import { Auth } from "aws-amplify";
import {
  detectBrowser,
  detectOS,
  getLandingPage,
  sanitizeInputWithSpace,
} from "services/utils";

/**
 * Get current login session
 *
 * @returns current auth session
 */
const getCurrentAuthSession = () => {
  return Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
};

/**
 * Get current auth user id and email
 *
 * @returns {id, email}
 */
const getCurrentAuthUserInfo = () => {
  return Auth.currentAuthenticatedUser({
    bypassCache: true,
  }).then((session) => {
    return {
      id: session.attributes.sub,
      username: session.username,
      email: session.attributes.email,
      abn: session.attributes["custom:abn_number"],
      purchase: session.attributes["custom:purchase"],
    };
  });
};

/**
 * Check if user is login or not
 * Set the state accordingly if provided
 * @param {fn} setLoggedIn
 */
const checkLoginState = () =>
  getCurrentAuthUserInfo()
    .then((sess) => {
      return sess;
    })
    .catch((error) => {
      return undefined;
    });

/**
 * Log out of current session
 * @param {fn} setLoggedIn
 */
const signOut = async (onSignout = () => {}) => {
  try {
    await Auth.signOut();
    onSignout();
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

/**
 * Google SSO
 */

const signupWithGoogle = ({ ABN }) => {
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: `{"name": "SSO_POST_SIGNUP", "data": {"ABN": "${ABN}"}}`,
  });
};

const signinWithGoogle = () => {
  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
};

const partnerSigninWithGoogle = () => {
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: `{"name": "SSO_POST_SIGNUP", "data": {"partner": "true"}}`,
  });
};

/**
 * Facebook SSO
 */

const signupWithFacebook = ({ ABN }) => {
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Facebook,
    customState: `{"name": "SSO_POST_SIGNUP", "data": {"ABN": "${ABN}"}}`,
  });
};

const signinWithFacebook = () => {
  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
};

const postSignupWithSSO = ({ ABN, setSession = () => {} }) => {
  Auth.currentAuthenticatedUser({
    bypassCache: true,
  })
    .then((sess) => {
      postSignupWithSSO_API({
        username: sess.username,
        ABN,
        firstName: sess.attributes["given_name"],
        lastName: sess.attributes["family_name"],
        email: sess.attributes["email"],
      }).then((result) => {
        Auth.currentAuthenticatedUser({
          bypassCache: true,
        }).then((s) => {
          setSession({
            id: s.attributes.sub,
            username: s.username,
            email: s.attributes.email,
            abn: "SSO_MANAGED",
          });
        });
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

const partnerPostSigninWithSSO = ({
  setSession = () => {},
  onError = () => {},
}) => {
  Auth.currentAuthenticatedUser({
    bypassCache: true,
  })
    .then((sess) => {
      verifyPartnerAccount({
        username: sess.username,
        email: sess.attributes["email"],
      }).then((result) => {
        if (result === true) {
          Auth.currentAuthenticatedUser({
            bypassCache: true,
          }).then((s) => {
            setSession({
              id: s.attributes.sub,
              username: s.username,
              email: s.attributes.email,
              abn: "SSO_MANAGED",
            });
          });
        } else {
          onError();
        }
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

// =========== //

/**
 *
 * @param {string} email
 * @param {string} password
 * @param {fn} navigateCallback
 * @param {fn} onSigninCallback
 */
const signIn = async ({
  email = "",
  password = "",
  navigateCallback = () => {},
  onSigninCallback = () => {},
  confirmCallback = () => {},
  mfaCallback = () => {},
}) => {
  try {
    if (!email || !password) {
      throw new Error();
    }
    let result = await Auth.signIn(email, password);
    if (result.challengeName === "SOFTWARE_TOKEN_MFA") {
      mfaCallback(result);
    } else {
      await pushLoginEvent();
      navigateCallback();
      onSigninCallback();
    }
  } catch (error) {
    if (error.code === "UserNotConfirmedException") {
      console.error(error);
      confirmCallback();
      return;
    } else if (
      error.code === "NotAuthorizedException" ||
      error.code === "UserNotFoundException"
    ) {
      throw error;
    }
    console.error("Please provide valid email and password.");
  }
};

const partnerSignIn = async ({
  email = "",
  password = "",
  navigateCallback = () => {},
  onSigninCallback = () => {},
  confirmCallback = () => {},
  mfaCallback = () => {},
}) => {
  const verify = await verifyPartnerAccount({ username: null, email });

  if (verify) {
    await signIn({
      email,
      password,
      navigateCallback,
      onSigninCallback,
      confirmCallback,
      mfaCallback,
    });
  } else {
    window.location.href = `${getLandingPage()}/login?email_fail=true`;
  }
};

/**
 * Register new user information to User Pools
 *
 * @param {Object} input
 * @param {fn} confirmCallback
 * @param {fn} onSignupCallback
 */
const signUp = async (
  input = {},
  confirmCallback = () => {},
  onSignupCallback = () => {}
) => {
  let result;
  try {
    const { email, password, firstName, lastName, abn } = input;
    if (!email || !password || !firstName || !lastName || !abn) {
      throw new Error();
    }
    let stripePurchaseSession = sessionStorage.getItem(
      "STRIPE_PURCHASE_SESSION"
    );
    result = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName,
        "custom:abn_number": sanitizeInputWithSpace(abn),
        "custom:purchase": stripePurchaseSession,
        "custom:location_origin": window.location.origin,
      },
    });
  } catch (error) {
    if (error.code === "UsernameExistsException") {
      throw error;
    }
    console.error("Failed to register new account.");
    console.error(error);
  }
  if (!result) {
    throw new Error("register failed");
  }
  if (!result.userConfirmed) {
    confirmCallback();
  }
  onSignupCallback(result);
};

/**
 * Confirm user register information
 * with email confirmation code
 * to add user to Federated Identities
 *
 * @param {string} username
 * @param {string} code
 * @param {fn} navigateCallback
 * @param {fn} confirmCallback
 */
const confirmSignup = async (
  username,
  code,
  navigateCallback = () => {},
  confirmCallback = () => {}
) => {
  try {
    await Auth.confirmSignUp(username, code);
    navigateCallback();
    confirmCallback();
  } catch (error) {
    console.error("Failed to confirm register information.");
    throw error;
  }
};

/**
 * Resend confirmation code to user email
 *
 * @param {string} username
 */
const resendConfirmationCode = async (username) => {
  try {
    await Auth.resendSignUp(username);
  } catch (error) {
    console.error("Failed to resend code.");
  }
};

/**
 * Send confirmation code to user's email
 * for password reset
 *
 * @param {*} email
 * @param {*} callbackFn
 * @returns
 */
const forgotPassword = async (
  email,
  callbackFn = () => {},
  onError = () => {}
) => {
  return Auth.forgotPassword(email)
    .then((data) => {
      callbackFn();
    })
    .catch((err) => {
      console.error("operation failed");
      onError(err);
    });
};

const forgotPasswordConfirmReset = async (
  email,
  code,
  newPwd,
  callbackFn = () => {},
  onError = () => {}
) => {
  try {
    let user;
    try {
      user = await Auth.signIn(email, newPwd); // should fail
    } catch (err) {
      //
    }
    if (!!user) {
      const err = new Error();
      err.code = "PasswordInUse";
      throw err;
    }
    return Auth.forgotPasswordSubmit(email, code, newPwd)
      .then((data) => {
        callbackFn(newPwd);
      })
      .catch((err) => {
        console.error("operation failed");
        onError(err);
      });
  } catch (err) {
    await Auth.signOut();
    onError(err);
  }
};

const forgotPasswordConfirmCode = async (
  email,
  code,
  callbackFn = () => {},
  onError = () => {}
) => {
  const tmppwd = Math.random().toString(36).slice(-8);
  return Auth.forgotPasswordSubmit(email, code, tmppwd)
    .then((data) => {
      callbackFn(tmppwd);
    })
    .catch((err) => {
      console.error("operation failed");
      onError(err);
    });
};

const forgotPasswordSubmit = async (
  email,
  tmppwd,
  newpwd,
  callbackFn = () => {},
  onError = () => {}
) => {
  try {
    const user = await Auth.signIn(email, tmppwd);
    return await Auth.changePassword(user, tmppwd, newpwd)
      .then(async (data) => {
        try {
          await Auth.signOut();
        } catch (err) {
          //
        }
        callbackFn();
      })
      .catch((err) => {
        console.error("operation failed");
        onError(err);
      });
  } catch (err) {
    console.error(err);
  }
};

const changePassword = async (pwd, newpwd, onError = () => {}) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    return await Auth.changePassword(currentUser, pwd, newpwd).catch((err) => {
      onError(err);
    });
  } catch (err) {
    onError(err);
  }
};

const checkUserRegisteredDetails = async ({
  setSession = () => {},
  userNotFoundFn = () => {},
  doubleChecked = false,
}) => {
  const authUser = await getCurrentAuthUserInfo();
  const user = await getUser(authUser.id);
  if (!!user) {
    const organisation = await getOrganisation(user.ABN);
    if (!!organisation) {
      localStorage.setItem("ACCESS_HISTORY", 1);
      setSession();
      return;
    }
  }
  const sess = await AuthService.getCurrentAuthSession();
  const identities = sess?.attributes?.identities;

  if (doubleChecked) {
    userNotFoundFn();
    setTimeout(() => {
      Auth.signOut();
    }, 3000);
  } else if (
    !!identities &&
    (JSON.parse(identities)[0].providerName === "Google" ||
      JSON.parse(identities)[0].providerName === "Facebook")
  ) {
    // double check so registered user's data
    // is populated
    setTimeout(() => {
      checkUserRegisteredDetails({
        setSession,
        userNotFoundFn,
        doubleChecked: true,
      });
    }, 5000);
  }
};

const verifyMFA = async (
  email,
  password,
  code,
  navigateCallback = () => {},
  onSigninCallback = () => {}
) => {
  try {
    const user = await Auth.signIn(email, password);
    const loggedUser = await Auth.confirmSignIn(user, code, user.challengeName);
    await pushLoginEvent();
    navigateCallback();
    onSigninCallback();
  } catch (e) {
    console.error(e);
  }
};

export const AuthService = {
  getCurrentAuthSession,
  getCurrentAuthUserInfo,
  checkLoginState,
  signOut,
  signIn,
  signUp,
  confirmSignup,
  resendConfirmationCode,
  forgotPassword,
  forgotPasswordSubmit,
  signinWithFacebook,
  signupWithFacebook,
  postSignupWithSSO,
  changePassword,
  forgotPasswordConfirmCode,
  signupWithGoogle,
  signinWithGoogle,
  checkUserRegisteredDetails,
  forgotPasswordConfirmReset,
  partnerSigninWithGoogle,
  partnerPostSigninWithSSO,
  partnerSignIn,
  verifyMFA,
};
