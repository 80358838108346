import {
  BadgeBlank,
  BadgeBronze,
  BadgeDiamond,
  BadgeGold,
  BadgePlatinum,
  BadgeSilver,
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
  WorkbookCoverBronze,
  WorkbookCoverDiamond,
  WorkbookCoverGold,
  WorkbookCoverPlatinum,
  WorkbookCoverSilver,
} from "assets";

export const BADGES = {
  0: BadgeBlank,
  1: BadgeBronze,
  2: BadgeSilver,
  3: BadgeGold,
  4: BadgePlatinum,
  5: BadgeDiamond,
};

export const BADGE_ANIMS = {
  1: "/animations/badge-level-1-bronze.webm",
  2: "/animations/badge-level-2-silver.webm",
  3: "/animations/badge-level-3-gold.webm",
  4: "/animations/badge-level-4-platinum.webm",
  5: "/animations/badge-level-5-diamond.webm",
};

export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  REGISTER_CONFIRM: "/register/confirm",
  PROFILE: "/profile",
  PROFILE_SETUP: "/profile/setup",
  DASHBOARD: "/dashboard",
  CERTIFICATION_MANAGER: "/certification",
  ACCOUNT: "/account",
  TERMS: "/terms",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE: "/cookie",
  RESET_PASSWORD: "/reset-password",
  SSO_FAIL: "/sso/error",
  CLAIM: "/claim",
  CERTIFICATE_VIEW: "/certificate/view",
  MAINTENANCE: "/maintenance",
  ASSESSMENT_TOOL: "/recommendation-tool",
  ASSESSMENT_RESULTS: "/recommendation-tool/results",
  ISSUED: "/issued/:id",
  ISSUED_SAMPLE: "/issued-sample/:level",
  UPGRADES: "/upgrades",
  OPTIONS: "/certificate-options",
  MFA_VERIFY: "/mfa/verify",
  404: "/404",
  PAGE_NOT_FOUND: "*",
};

export const CERTIFICATION_MANAGER = {
  ATTESTATION_DEED: {
    ROLES: ["Director", "Owner", "Officer", "Senior Executive"],
    TITLE: ["Mr", "Mrs", "Ms", "Dr"],
  },
};

export const CERTIFICATION_STEPS_DEFAULT = 7;

export const CERTIFICATION_STEPS = {
  1: 5,
  2: 5,
  3: 5,
  // 4: 6,
  // 5: 6,
};

export const CERTIFICATION_STEP_TITLES = {
  1: "Download Requirements",
  2: "Complete Attestation",
  3: "Create Attestation Letter",
  4: "Sign Attestation Letter",
  5: "Request Audit Review",
  6: "Download your Certificate",
  7: "Share your Certificate",
};

export const CERT_LVLS = {
  0: "Blank",
  1: "Bronze",
  2: "Silver",
  3: "Gold",
  4: "Platinum",
  5: "Diamond",
};

export const CERT_LVL_ICONS = {
  0: "/assets/icons/level-0-blank.png",
  1: "/assets/icons/level-1-bronze.png",
  2: "/assets/icons/level-2-silver.png",
  3: "/assets/icons/level-3-gold.png",
  4: "/assets/icons/level-4-platinum.png",
  5: "/assets/icons/level-5-diamond.png",
};

export const CERT_LVL_BADGES = {
  0: BadgeBlank,
  1: BadgeBronze,
  2: BadgeSilver,
  3: BadgeGold,
  4: BadgePlatinum,
  5: BadgeDiamond,
};

export const CERT_LVL_ANIMS_SAFARI = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-animated-sf.mov",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-animated-sf.mov",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-animated-sf.mov",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-animated-sf.mov",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-animated-sf.mov",
  All: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/spin-all-levels-sf.mov",
};

export const CERT_LVL_ANIMS_CHROME = {
  1: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/bronze-animated-cr.webm",
  2: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/silver-animated-cr.webm",
  3: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/gold-animated-cr.webm",
  4: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/platinum-animated-cr.webm",
  5: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/diamond-animated-cr.webm",
  All: "https://cybercert.s3.ap-southeast-2.amazonaws.com/generic/badges/spin-all-levels-cr.webm",
};

export const AUDIT_LEVEL = 4;

export const ATTESTATION_STATUS = {
  completed: "COMPLETED",
  na: "NA",
};

export const CERTIFICATION_PROGRESS_STATUS = {
  in_progress: "IN_PROGRESS",
  completed: "COMPLETED",
  failed: "FAILED",
};

export const PRICING = {
  1: 95,
  2: 195,
  3: 395,
  // 4: 1995,
  // 5: 2995,
};

export const CERTIFICATION_INFO = {
  1: {
    marketingName: "Bronze",
    description:
      "For organisations that are starting out on their cyber journey and need the basics in place.",
    validFor: 12, // months
    assuranceLevel: "Self Attested",
    difficulty: "Easy",
  },
  2: {
    marketingName: "Silver",
    description:
      "For organisations that rely on email and business apps, and want advanced cyber security.",
    validFor: 12, // months
    assuranceLevel: "Self Attested",
    difficulty: "Easy",
  },
  3: {
    marketingName: "Gold",
    description:
      "For organisations that want professional level cyber security that demonstrates best practice.",
    validFor: 12, // months
    assuranceLevel: "Self Attested",
    difficulty: "Medium",
  },
  4: {
    marketingName: "Platinum",
    description:
      "For high performing businesses that have mature cyber security built on best-practice cyber risk management.",
    validFor: 12, // months
    assuranceLevel: "Verified",
    difficulty: "Hard",
  },
  5: {
    marketingName: "Diamond",
    description:
      "Top level certification for businesses that have the highest level of cyber maturity across all 5 core cyber security pillars.",
    validFor: 12, // months
    assuranceLevel: "Verified",
    difficulty: "Extra Hard",
  },
};

export const DATE_PLACEHOLDER = "DD/MM/YYYY";

export const LEVEL_NAMES = ["Bronze", "Silver", "Gold", "Platinum", "Diamond"];

export const LEVEL_ICONS = [
  IconBronze,
  IconSilver,
  IconGold,
  IconPlatinum,
  IconDiamond,
];

export const LEVEL_WORKBOOK_COVERS = {
  1: WorkbookCoverBronze,
  2: WorkbookCoverSilver,
  3: WorkbookCoverGold,
  4: WorkbookCoverPlatinum,
  5: WorkbookCoverDiamond,
};

export const RESEND_TIMEOUT = 2; // minutes
