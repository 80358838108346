import './step-3.scss';

import {
  useEffect,
  useState,
} from 'react';

import { FormInput } from 'components';
import { useLoadingState } from 'middleware';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import {
  filterAlphaKeyPress,
  filterAlphaPaste,
  filterPhoneNoKeyPress,
  filterPhoneNoPaste,
  validatePhoneNumber,
} from 'services';
import { CERTIFICATION_MANAGER } from 'variables';

import {
  StepIcon,
  StepIconArrow,
} from '../icons';

const initialData = {
  role: undefined,
  title: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  mobile: undefined,
};

const initialValidation = {
  firstName: "success",
  lastName: "success",
  email: "NA",
  mobile: "success",
  role: "NA",
  title: "NA",
};

const initialErrorTxt = {
  firstName: "First name is required.",
  lastName: "Last name is required.",
  email: "Please enter a valid email address.",
  mobile: "Mobile phone number is required.",
  role: "Role is not selected.",
  title: "Title is not selected.",
};

const validationRules = ["firstName", "lastName", "email", "mobile"];

export function Step3({
  assessStep,
  setCurrentStep = () => 1,
  done,
  progressData = {},
  getCurrentStep = () => {},
}) {
  const [data, setData] = useState(initialData);
  const [validation, setValidation] = useState(initialValidation);
  const [errorTxt, setErrorTxt] = useState(initialErrorTxt);
  const [disabled, setDisabled] = useState(true);
  const [showConfirm, setShowConfirm] = useState();
  const { setLoading } = useLoadingState();

  useEffect(() => {
    setData({
      ...data,
      role: progressData.role ? progressData.role : data.role,
      title: progressData.title ? progressData.title : data.title,
      firstName: progressData.firstName,
      lastName: progressData.lastName,
      email: progressData.email,
      mobile: progressData.mobile,
    });
  }, []);

  const assess = () => {
    return assessStep(3, data);
  };

  function handleInput(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  }

  const pSetValidation = (name, value) => {
    if (
      value === "error" &&
      ["firstName", "lastName", "mobile"].includes(name)
    ) {
      return;
    }
    const validationResult = { ...validation, [name]: value };
    setValidation(validationResult);
    setDisabled(
      !validationRules.every((prop) => validationResult[prop] === "success"),
    );
  };

  const validateSubmit = (event) => {
    setLoading(true);

    let validationResult = validation;
    let errorTxtResult = errorTxt;
    let canContinue = true;
    ["firstName", "lastName", "mobile", "role", "title", "email"].forEach(
      (prop) => {
        if (
          prop === "mobile" &&
          !!data["mobile"] &&
          !validatePhoneNumber(data["mobile"])
        ) {
          validationResult["mobile"] = "error";
          errorTxtResult["mobile"] = "Contact number is invalid.";
          canContinue = false;
        } else if (!data[prop]) {
          validationResult[prop] = "error";
          errorTxtResult[prop] = initialErrorTxt[prop];
          canContinue = false;
        }
      },
    );

    setValidation(validationResult);

    const canSubmit = validationRules.every((prop) => !!data[prop]);

    if (!canSubmit || !canContinue) {
      setDisabled(true);
      setLoading(false);
    } else {
      assess()
        .then(() => {
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  const handleShowConfirm = (e) => {
    e.preventDefault();

    setShowConfirm(true);
  };

  const handleConfirm = () => {
    setShowConfirm(false);

    validateSubmit();
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    setCurrentStep(4);
  };

  return (
    <>
      <Card
        className={`cert-step-3 acc-item ${done ? "done" : getCurrentStep() === "3" ? "current" : ""}`}
      >
        <Card.Header>
          <div className="acc-header" onClick={() => setCurrentStep(3)}>
            {done ? <StepIcon /> : <StepIconArrow />}

            <h3>Step 3: Create Attestation Letter</h3>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <div className="acc-content">
              <div className="step-block">
                <div className="df-row">
                  <div className="col-content">
                    <div className="step-content">
                      <strong className="step-title">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C14.748 0 19 4.25202 19 9.5C19 14.748 14.748 19 9.5 19C4.25202 19 0 14.748 0 9.5C0 4.25202 4.25202 0 9.5 0ZM8.39294 5.50081L11.2851 8.27419H4.29032C3.78085 8.27419 3.37097 8.68407 3.37097 9.19355V9.80645C3.37097 10.3159 3.78085 10.7258 4.29032 10.7258H11.2851L8.39294 13.4992C8.02137 13.8554 8.01371 14.4492 8.37762 14.8131L8.79899 15.2306C9.15907 15.5907 9.74133 15.5907 10.0976 15.2306L15.1808 10.1512C15.5409 9.79113 15.5409 9.20887 15.1808 8.85262L10.0976 3.76552C9.7375 3.40544 9.15524 3.40544 8.79899 3.76552L8.37762 4.18306C8.01371 4.55081 8.02137 5.14456 8.39294 5.50081Z"
                            fill="black"
                          />
                        </svg>
                        Instructions
                      </strong>
                      <div className="step-text">
                        <p>
                          Creating an Attestation Letter is a very important
                          part of your certification. The Attestation Letter is
                          a legal document where a Director or an owner of the
                          organisation is confirming all of the certification
                          requirements have been implemented in the
                          organisation.
                        </p>
                        <p>
                          Enter the details of the Director or the owner of the
                          organisation who will be signing the attestation deed.
                        </p>
                        <p>
                          When you have completed entering this information
                          click ‘Next Step’ to move to{" "}
                          <strong>STEP 4 – Sign Attestation Letter.</strong>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-info">
                    <div className="card-lvl">
                      <h4 className="card-title">Create Attestation Letter</h4>

                      <Form className="form-elements">
                        <div className="form-row full-input mb-3">
                          <FormInput
                            controlId="role"
                            options={
                              CERTIFICATION_MANAGER.ATTESTATION_DEED.ROLES
                            }
                            label="Role"
                            type="select"
                            name="role"
                            placeholder="Select"
                            onChange={handleInput}
                            errorTxt=""
                            pValidation={validation.role}
                            pSetValidation={(value) =>
                              pSetValidation("role", value)
                            }
                            required
                            disabled={done}
                          />
                        </div>

                        <div className="form-row full-input mb-3">
                          <FormInput
                            controlId="title"
                            options={
                              CERTIFICATION_MANAGER.ATTESTATION_DEED.TITLE
                            }
                            label="Title"
                            type="select"
                            name="title"
                            placeholder="Select"
                            errorTxt=""
                            pValidation={validation.title}
                            pSetValidation={(value) =>
                              pSetValidation("title", value)
                            }
                            onChange={handleInput}
                            required
                            disabled={done}
                          />
                        </div>

                        <div className="form-row">
                          <FormInput
                            controlId="fName"
                            label="First Name"
                            type="text"
                            name="firstName"
                            maxLength="30"
                            pValidation={validation.firstName}
                            pSetValidation={(value) =>
                              pSetValidation("firstName", value)
                            }
                            errorTxt=""
                            onChange={handleInput}
                            required
                            disabled={done}
                            onKeyPress={filterAlphaKeyPress}
                            onPaste={filterAlphaPaste}
                          />
                        </div>

                        <div className="form-row">
                          <FormInput
                            controlId="lName"
                            label="Last Name"
                            type="text"
                            name="lastName"
                            maxLength="30"
                            pValidation={validation.lastName}
                            pSetValidation={(value) =>
                              pSetValidation("lastName", value)
                            }
                            errorTxt=""
                            onChange={handleInput}
                            required
                            disabled={done}
                            onKeyPress={filterAlphaKeyPress}
                            onPaste={filterAlphaPaste}
                          />
                        </div>

                        <div className="form-row">
                          <FormInput
                            controlId="email"
                            label="Work email address"
                            type="email"
                            name="email"
                            maxLength="240"
                            pValidation={validation.email}
                            pSetValidation={(value) =>
                              pSetValidation("email", value)
                            }
                            errorTxt=""
                            onChange={handleInput}
                            required
                            disabled={done}
                          />
                        </div>

                        <div className="form-row">
                          <FormInput
                            controlId="phone"
                            label="Contact number"
                            type="text"
                            name="mobile"
                            maxLength="30"
                            pValidation={validation.mobile}
                            pSetValidation={(value) =>
                              pSetValidation("mobile", value)
                            }
                            errorTxt=""
                            onChange={handleInput}
                            required
                            onKeyPress={filterPhoneNoKeyPress}
                            onPaste={filterPhoneNoPaste}
                            disabled={done}
                          />
                        </div>

                        <div className="form-validation error">
                          <div className="validation-txt">
                            {!!errorTxt.firstName &&
                              validation.firstName === "error" && (
                                <p className="v-text error">
                                  {errorTxt.firstName}
                                </p>
                              )}
                            {!!errorTxt.lastName &&
                              validation.lastName === "error" && (
                                <p className="v-text error">
                                  {errorTxt.lastName}
                                </p>
                              )}
                            {!!errorTxt.email &&
                              validation.email === "error" && (
                                <p className="v-text error">{errorTxt.email}</p>
                              )}
                            {!!errorTxt.mobile &&
                              validation.mobile === "error" && (
                                <p className="v-text error">
                                  {errorTxt.mobile}
                                </p>
                              )}
                            {!!errorTxt.role && validation.role === "error" && (
                              <p className="v-text error">{errorTxt.role}</p>
                            )}
                            {!!errorTxt.title &&
                              validation.title === "error" && (
                                <p className="v-text error">{errorTxt.title}</p>
                              )}
                          </div>
                        </div>

                        <div className="form-row submit-row">
                          <button
                            onClick={done ? handleNextStep : handleShowConfirm}
                            className="btn btn-warning"
                            type="submit"
                          >
                            {done ? "Next Step" : "Submit"}
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Header closeButton>
          <Modal.Title className="step-2-confirm-title">
            Confirm the signee details...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please ensure that the signee details are correct before proceeding
            as submitting this form will lock in these details and prevent any
            further changes.
          </p>

          <p>
            These details will be considered as the primary point of contact for
            auditing purposes in the future.
          </p>

          {/* <table className="confirm-requirements-table">
            <thead>
              <tr>
                <td colSpan={2}>Signee Details</td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="requirement-data">Role</td>
                <td className="requirement-data">
                  <span>{data?.role}</span>
                </td>
              </tr>
              <tr>
                <td className="requirement-data">Title</td>
                <td className="requirement-data">
                  <span>{data?.title}</span>
                </td>
              </tr>
              <tr>
                <td className="requirement-data">First Name</td>
                <td className="requirement-data">
                  <span>{data?.firstName}</span>
                </td>
              </tr>
              <tr>
                <td className="requirement-data">Last Name</td>
                <td className="requirement-data">
                  <span>{data?.lastName}</span>
                </td>
              </tr>
              <tr>
                <td className="requirement-data">Email</td>
                <td className="requirement-data">
                  <span>{data?.email}</span>
                </td>
              </tr>
              <tr>
                <td className="requirement-data">Phone</td>
                <td className="requirement-data">
                  <span>{data?.mobile}</span>
                </td>
              </tr>
            </tbody>
          </table> */}

          <div className="confirm-signee-wrapper">
            <div className="signee-details">
              <h4>Signee Details</h4>
            </div>
            <div>
              <div className="signee-data">
                <span className="title">Role:</span> <span>{data?.role}</span>
              </div>
              <div className="signee-data">
                <span className="title">Title:</span> <span>{data?.title}</span>
              </div>
              <div className="signee-data">
                <span className="title">First Name:</span>{" "}
                <span>{data?.firstName}</span>
              </div>
              <div className="signee-data">
                <span className="title">Last Name:</span>{" "}
                <span>{data?.lastName}</span>
              </div>
              <div className="signee-data">
                <span className="title">Email:</span> <span>{data?.email}</span>
              </div>
              <div className="signee-data">
                <span className="title">Phone:</span>{" "}
                <span>{data?.mobile}</span>
              </div>
            </div>
          </div>

          <p className="c2c">Confirm to continue.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="step-2-confirm-btn"
            variant="secondary"
            onClick={handleCloseConfirm}
          >
            Cancel
          </Button>

          <Button
            className="step-2-confirm-btn confirm"
            variant="primary"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
