/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://84ib2fvbkf.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "GAP",
            "endpoint": "https://kcpx87i9y5.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "activities",
            "endpoint": "https://nlgx6esft1.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "apiAdmin",
            "endpoint": "https://v3qtddorp8.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "attestation",
            "endpoint": "https://2p5jt6fto6.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "certification",
            "endpoint": "https://xtbqvdgxs7.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "cybercertSSOPostSignup",
            "endpoint": "https://kmaq9ns8y1.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "gcp",
            "endpoint": "https://l97abs6jg1.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "getBadge",
            "endpoint": "https://aefaaimzvg.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "issueBadge",
            "endpoint": "https://8hw8i6wq7c.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "maintenance",
            "endpoint": "https://3i40ov6gs6.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "organisation",
            "endpoint": "https://edcqmco0lc.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "partner",
            "endpoint": "https://eohgdbea8j.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "privateDoc",
            "endpoint": "https://xhgg3jtknk.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "public",
            "endpoint": "https://huled6gkuj.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "publicDoc",
            "endpoint": "https://fayar0vr9h.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "s3",
            "endpoint": "https://087twckbdk.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "signconnect",
            "endpoint": "https://3hjpmhy86e.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "stripe",
            "endpoint": "https://yqosdfsvqg.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "t2",
            "endpoint": "https://e9qmhhoy89.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "user",
            "endpoint": "https://a6tbii6hqk.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://tppepdec7zfzdlmcqemklj6som.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-qedhqyb22vg3heusfj56zu57hm",
    "aws_cognito_identity_pool_id": "ap-southeast-2:1eab21ee-586a-40f2-9477-49d377112453",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_WL81ARQLO",
    "aws_user_pools_web_client_id": "72psqbh5ai907nh9o0ubi0u8o7",
    "oauth": {
        "domain": "cybercertcertificati6a1c06cf-6a1c06cf-production.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://cmxdev.cybercert.au/,https://cybercert.au/,https://cert.cybermetrix.com.au/,https://cybercert.com.au/,https://cybercert.ai/,https://www.cybercert.ai/,https://dashboard.cybercert.au/,https://partners.cybercert.ai/,https://certification.cybercert.ai/,https://dashboard.cybercert.ai/",
        "redirectSignOut": "http://localhost:3000/,https://cmxdev.cybercert.au/,https://beta.cybercert.au/,https://cybercert.au/,https://cybercert.com.au/,https://cybercert.ai/,https://www.cybercert.ai/,https://dashboard.cybercert.au/,https://partners.cybercert.ai/,https://certification.cybercert.ai/,https://dashboard.cybercert.ai/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
