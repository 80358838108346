import { API } from "aws-amplify";

export const verifyPartnerAccount = async ({ username, email }) => {
  const myInit = {
    queryStringParameters: {
      username,
      email,
    },
  };
  const result = await API.get("partner", "/verify", myInit);
  // console.log(result);
  return result;
};
