import { API } from "aws-amplify";

export const postSignupWithSSO = async ({
  username,
  ABN,
  firstName,
  lastName,
  email,
}) => {
  const myInit = {
    body: {
      username,
      ABN,
      firstName,
      lastName,
      email,
    },
  };
  const result = await API.post("cybercertSSOPostSignup", "/", myInit);
  // console.log(result);
  return result;
};
