import { useState } from 'react';

import { useLoadingState } from 'middleware';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { CertificationService } from 'services/certification';

import { StepIcon } from '../icons';

export function Step5({ setCurrentStep, done, getCurrentStep = () => {} }) {
  const { setLoading } = useLoadingState();
  const [requestRes, setRequestRes] = useState();

  const requestAudit = async () => {
    setLoading(true);

    await CertificationService.requestAudit()
      .then(() => {
        setRequestRes("success");
      })
      .catch((err) => {
        setRequestRes("error");
      });

    setLoading(false);
  };

  return (
    <>
      <Card
        className={`cert-step-5 acc-item ${done ? "done" : getCurrentStep() === "5" ? "current" : ""}`}
      >
        <Card.Header>
          <div className="acc-header" onClick={() => setCurrentStep(5)}>
            <StepIcon />

            <h3>Step 5: Request Audit Review</h3>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="5">
          <Card.Body>
            <div className="acc-content">
              <div className="step-block">
                <div className="df-row">
                  <div className="col-content">
                    <div className="step-content">
                      <strong className="step-title">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C14.748 0 19 4.25202 19 9.5C19 14.748 14.748 19 9.5 19C4.25202 19 0 14.748 0 9.5C0 4.25202 4.25202 0 9.5 0ZM8.39294 5.50081L11.2851 8.27419H4.29032C3.78085 8.27419 3.37097 8.68407 3.37097 9.19355V9.80645C3.37097 10.3159 3.78085 10.7258 4.29032 10.7258H11.2851L8.39294 13.4992C8.02137 13.8554 8.01371 14.4492 8.37762 14.8131L8.79899 15.2306C9.15907 15.5907 9.74133 15.5907 10.0976 15.2306L15.1808 10.1512C15.5409 9.79113 15.5409 9.20887 15.1808 8.85262L10.0976 3.76552C9.7375 3.40544 9.15524 3.40544 8.79899 3.76552L8.37762 4.18306C8.01371 4.55081 8.02137 5.14456 8.39294 5.50081Z"
                            fill="black"
                          />
                        </svg>
                        Instructions
                      </strong>

                      <div className="step-text">
                        <p>
                          Completing your audit review is the final step in the
                          certification process. The purpose of the audit review
                          is to verify the certification requirements have been
                          implemented in your business as per your responses in
                          the attestation deed.
                        </p>
                        <p>
                          When you are ready to schedule your audit review
                          please submit a request.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-info">
                    <div className="card-lvl">
                      <h4 className="card-title">Request Audit Review</h4>

                      <div className="text-audit">
                        <p>
                          <strong>Please note: </strong>Your certification fee
                          includes one (1) audit review. In the event that your
                          audit review identifies requirements that have not
                          been implemented, you will be given one (1)
                          opportunity to re-submit for a final audit review. In
                          the event your business fails the final audit review
                          you will be required to pay for all additional audit
                          reviews that you require for this certification.
                        </p>
                      </div>

                      <button
                        className="btn btn-warning"
                        onClick={requestAudit}
                        disabled={done}
                      >
                        Submit Request
                      </button>

                      <div className={`form-validation mt-20 ${requestRes}`}>
                        <div className="validation-txt">
                          <p className="v-text success">
                            Audit review request has been sent successfully.
                          </p>
                          <p className="v-text error">
                            Sending failed. Please try again.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
}
